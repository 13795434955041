import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]

  return (
    <section className={'svgBoxRectanguloBottonRigth'}>

      <div className="pic-wrapper svgHerosection2  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-end items-center pb-20">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[28px] md:text-[35px] lg:text-[50px]">
              {title}
            </h1>
            <p className="text-white text-center text-[14px] lg:text-[16px]">
              {texts}
            </p>
          </div>

          <div className="w-full flex justify-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>

        </div>
      </div>


    </section>

  )
}

export default HeroSection;
